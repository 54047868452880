import { parseUtmLabels } from "~/utils/forms/parseUtmLabels";

const setUtmToSessionStorage = () => {
  const utmLabels : Record<string, string> = parseUtmLabels()

  Object.keys(utmLabels).forEach((item: string) => {
    sessionStorage.setItem(item, utmLabels[item])
  })
}

export default setUtmToSessionStorage

<template>
  <div class="check-agree__root">
    <div
      v-if="!isShowCitiesList"
      class="head"
    >
      <span class="question-city">Ваш город {{ currentCity }}</span>
      <span
        class="agree-city-btn --agree"
        @click="checkUser"
      >Да</span>
      <span
        class="agree-city-btn --change"
        @click="showCitiesList"
      >Изменить</span>
    </div>
    <div
      v-else
      class="city-select__dropdown"
    >
      <ul>
        <li
          v-for="(city) in citiesList?.favorites"
          :key="`city-select-${ city.id }`"
          @click="() => onSetCityUser(city)"
        >
          {{ city.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { storeToRefs } from 'pinia';
import { useUserVisit } from '~/composables/useUserVisit';
import type { CityListCityType } from '~/composables/useUserCity';
import { useUserCity } from '~/composables/useUserCity';

const { closeToast } = useUserVisit();
const appConfigStore = useAppConfigStore();
const { config } = storeToRefs(appConfigStore);
const currentCity = computed(() => config.value?.city?.model?.name || config?.value?.city?.data?.city_name);
const isShowCitiesList = ref<boolean>(false);
const { getAllCitiesList, setCityUser, citiesList } = useUserCity();

const checkUser = () => {
  const userVisited = useCookie('user-visited', {
    maxAge: (60 * 60 * 24 * 30 * 12)
  });

  if (!userVisited.value) {
    userVisited.value = 'true';
  }
  closeToast();
};

const loadCitiesList = async () => {
  if (citiesList.value && Object.keys(citiesList.value).length > 0) { return; }
  await getAllCitiesList();
};

const onSetCityUser = async (city: CityListCityType) => {
  await setCityUser(city.id);
  checkUser();
};

const showCitiesList = async () => {
  isShowCitiesList.value = true;
  await loadCitiesList();
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.check-agree__root{
	background: var(--primary-90);
	padding: 16px;
	border-radius: 0 0 6px 6px;
	box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2px 4px 0 rgb(0 0 0 / 4%), 0 7px 7px 0 rgb(0 0 0 / 4%), 0 16px 9px 0 rgb(0 0 0 / 3%), 0 28px 11px 0 rgb(0 0 0 / 1%), 0 44px 12px 0 rgb(0 0 0 / 0%);

	.question-city{
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 120%; /* 15.6px */
		letter-spacing: -0.13px;
		color: var(--primary-40);
 	display: inline-block;
	}

	.head{
		display: flex;
		align-items: center;
		gap: 6px;
	}
}

.agree-city-btn{
	padding: 0 12px;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 24.825px;
	letter-spacing: -0.24px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 62.061px;
	box-shadow: 0 0 0 0 rgb(0 0 0 / 4%), 0 2.482px 4.965px 0 rgb(0 0 0 / 4%), 0 8.689px 8.689px 0 rgb(0 0 0 / 4%), 0 19.86px 11.171px 0 rgb(0 0 0 / 3%), 0 34.754px 13.653px 0 rgb(0 0 0 / 1%), 0 54.614px 14.895px 0 rgb(0 0 0 / 0%);
	cursor: pointer;

	&.--agree{
		color: var(--primary);
		border: 1.241px solid transparent;
		background: linear-gradient(to bottom, #ffb400, var(--secondary, #FFCC49) 50%);
	}

	&.--change{
		color: var(--secondary);
		border: 1.241px solid var(--secondary);
	}
}

.city-select__dropdown {
	width: 220px;
	background: var(--primary-90);

	ul {
		margin: 4px 0;

		& > li {
			cursor: pointer;
			padding: 8px 12px;
			font-size: 16px;
			line-height: 26px;
			color: #FFF;

			&:hover {
				margin: 0 4px;
				border-radius: 4px;
				font-weight: 700;
				color: var(--primary);
				background-color: var(--secondary);
			}
		}
	}
}

@include media('sm'){
	.check-agree__root{
		padding: 10px;
	}
}
</style>

import type { Ref } from '#imports';

type UseUserVisit = {
    isShowSelectCityToast: Ref<boolean>;
    closeToast:()=>void;
}

export const useUserVisit = ():UseUserVisit => {
  const isShowSelectCityToast = useState<boolean>('isShowSelectCityToast', () => true);

  const closeToast = () => {
    isShowSelectCityToast.value = false;
  };

  return {
    isShowSelectCityToast,
    closeToast
  };
};

<template>
  <div
    v-if="scY > 500"
    class="button-scroll-up"
    @click="toTop"
  >
    <img
      src="~/assets/img/svg/header/arrow-up-gray.svg"
      alt="up"
    />
  </div>
</template>

<script setup lang="ts">
const scTimer = ref<ReturnType<typeof setTimeout> | number>();
const scY = ref<number>(0);

const toTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

const handleScroll = () => {
  if (scTimer.value) {
    return null;
  }
  scTimer.value = setTimeout(() => {
    scY.value = window.scrollY;
    clearTimeout(scTimer.value);
    scTimer.value = 0;
  }, 100);
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.button-scroll-up {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 48px;
	height: 48px;
	background: #8A77A7;
	border-radius: 100%;
	position: fixed;
	z-index: 500;
	left: 20px;
	cursor: pointer;
	transition: all 0.5s;
	bottom: 20px;

	img {
		transition: all 0.2s;
	}

	&::after {
		content: "";
		position: absolute;
		inset: -3px;
		border: 1px solid #D8D2E2;
		border-radius: 100%;
		transition: all 0.2s;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}

		&::after {
			inset: -5px;
		}
	}
}

@include media('sm') {
  .button-scroll-up {
    left: unset;
    right: 20px;
  }
}
</style>

<template>
  <footer class="footer">
    <div class="container">
      <div class="footer-inner">
        <div class="footer-top">
          <div class="footer-info">
            <nuxt-link
              class="footer-logo-link"
              to="/"
            >
              <img
                :src="LogoIcon"
                alt="Footer Logo"
              >
            </nuxt-link>
            <div
              class="footer-info-address"
              v-html="footerInfo.address"
            ></div>
            <a
              class="footer-info-tel"
              :href="getCleanedPhoneForHref(footerInfo.phone)"
            >{{ footerInfo.phone }}</a>
            <div class="socials-desktop">
              <FooterSocials :socials-list="socialsList" />
            </div>
          </div>
          <FooterNav
            v-if="$viewport.isGreaterOrEquals('md')"
            :menus="footerMenu || []"
          />
        </div>
        <FooterNavMobile
          v-if="$viewport.isLessThan('md')"
          :menus="footerMenu || []"
        />
        <div class="socials-mobile">
          <FooterSocials
            :socials-list="socialsList"
            :title="false"
          />
        </div>
        <div class="footer-bottom">
          <div class="footer-bottom-left">
            <div
              class="footer-bottom-item --top"
              v-html="footerInfo.requisites"
            />
            <div
              class="footer-bottom-item --disc"
              v-html="footerInfo.disclaimer"
            />
          </div>
          <div class="footer-bottom-right">
            <div
              class="footer-bottom-item --proj"
              v-html="footerInfo.developer"
            />
          </div>
        </div>
        <div class="footer-year">© АСТОН {{ currentYear }}</div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import LogoIcon from 'assets/img/svg/footer/footer-logo.svg';
import { storeToRefs } from 'pinia';
import FooterNav from '~/components/common/Footer/FooterNav.vue';
import FooterNavMobile from '~/components/common/Footer/FooterNavMobile.vue';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { cmsContentStore as useCmsContentStore } from '#sitis/stores/modules/cms-content-store';
import type { IContentMenuItem } from '#sitis/internal/controllers/cms/models/ContentMenu';
import type { ComputedRef } from '#imports';
import { useAsset } from '~/composables/useAssets';
import FooterSocials from '~/components/common/Footer/FooterSocials.vue';

const $viewport = useViewport();
const cmsContentStore = useCmsContentStore();
const appConfigStore = useAppConfigStore();
const { menus } = storeToRefs(cmsContentStore);
const { vars } = storeToRefs(appConfigStore);

export type FooterSocialsType = {
	link: string;
	alt: string;
	icon: string;
}

const footerMenu: ComputedRef<IContentMenuItem[]> = computed(() => menus.value?.footer?.items);

const footerInfo = computed(() => ({
  address: vars.value['Адрес офиса продаж'] || '',
  phone: vars.value['Телефон офиса продаж'] || '',
  requisites: vars.value['Реквизиты в подвале сайта'] || '',
  disclaimer: vars.value['Дисклаймер (в подвале сайта)'] || '',
  developer: vars.value['Застройщик (в подвале сайта)'] || ''
}));

const socialsList: ComputedRef<FooterSocialsType[]> = computed(() => {
  const list = [];

  if (vars.value['Соцсети_VK']) {
    list.push({
      link: vars.value['Соцсети_VK'],
      alt: 'VK',
      icon: useAsset('img/svg/footer/vk.svg')
    });
  }

  if (vars.value['Соцсети_Telegram']) {
    list.push({
      link: vars.value['Соцсети_Telegram'],
      alt: 'Telegram',
      icon: useAsset('img/svg/footer/tg.svg')
    });
  }

  if (vars.value['Соцсети_YouTube']) {
    list.push({
      link: vars.value['Соцсети_YouTube'],
      alt: 'YouTube',
      icon: useAsset('img/svg/footer/youtube.svg')
    });
  }

  if (vars.value['Соцсети_Дзен']) {
    list.push({
      link: vars.value['Соцсети_Дзен'],
      alt: 'Дзен',
      icon: useAsset('img/svg/footer/yadzen.svg')
    });
  }

  return list;
});

const currentYear = computed(() => new Date().getFullYear());

</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.footer {
	position: relative;
	z-index: 10;
	padding: 24px 0 35px;
	background: var(--primary);
}

.footer-top {
	display: flex;
	justify-content: space-between;
	padding-bottom: 25px;
	border-bottom: 1px solid var(--primary-80);
}

.footer-info {
	width: 20%;
	display: flex;
	flex-direction: column;

	&-address {
		color: var(--white);
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: -0.4px;
		margin-bottom: 16px;
	}

	&-tel {
		color: var(--white);
		font-size: 24px;
		font-style: normal;
		font-weight: 400;
		line-height: 31px;
		letter-spacing: -0.48px;
		transition: opacity .2s linear;
		margin-bottom: 32px;

		&:hover {
			opacity: .7;
		}
	}
}

.footer-logo-link {
	margin-bottom: 18px;
	width: 155px;
	height: 50px;

	& > img {
		width: 100%;
		height: 100%;
	}
}

.footer-bottom {
	display: flex;
	justify-content: space-between;
	gap: 80px;
	margin-top: 24px;

	&-item {
		color: rgb(255 255 255 / 60%);

		&.--top{
			font-size: 13px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 16.9px */
			letter-spacing: -0.26px;
		}

		&.--disc,
		&.--proj{
			font-size: 11px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%; /* 14.3px */
			letter-spacing: -0.22px;
		}
	}

	&-left {
		width: 60%;
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	&-right {
		width: 40%;
	}
}

.socials-mobile {
	display: none;
	margin-top: 24px;
}

.btn-link {
  margin-top: 24px;
  color: var(--primary-40);
  font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
		height: 32px;
		background: transparent;

	&.--mob{
		display: none;
	}

	& span {
    color: var(--white);
  }

	& img{
		margin-left: 10px;
	}

}

.footer-year {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-size: 14px;
  margin-top: 20px;
}

@include media('xl') {
	.btn-link{
		&.--desc{
			display: none;
		}

		&.--mob{
			display: flex;
		}
	}

	.footer-top {
		flex-direction: column;
		align-items: center;
	}

	.footer-info {
		width: 100%;
		align-items: center;

		&-tel {
			margin-bottom: 0;
		}
	}

	.footer-bottom {
		flex-direction: column;
		gap: 0;

		&-left, &-right {
			width: 100%;
		}
	}
}

@include media('md') {
	.footer{
		padding: 20px 0;
	}

	.socials-mobile {
		display: block;
		margin-top: 8px;
	}

	.socials-desktop {
		display: none;
	}

	.footer-logo-link {
		margin-bottom: 0;
	}

	.footer-top {
		padding-bottom: 12px;
		border-bottom: none;
	}

	.footer-bottom{
		margin-top: 12px;
	}

	.footer-info {
		align-items: flex-start;
		gap: 10px;

		&-address {
			font-size: 16px;
			margin-bottom: 0;
		}

		&-tel {
			font-size: 18px;
			font-weight: 600;
		}
	}

	.footer-bottom-item {
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 16px;
	}

  .btn-link {
    width: 100%;
    font-size: 12px;
    height: 32px;
			margin-top: 12px;
  }
}

</style>

import type { Ref } from 'vue';

interface IVideoplayerPopupState {
    title?: string;
    subtitle?: string;
    type?: string;
    ymSendForm?:string;
    videoLink?:string;
}

interface IUseVideoplayerPopup {
    isVideoPlayerPopupOpen: Ref<boolean>;
    videoplayerState:Ref<IVideoplayerPopupState>;

    openVideoplayerPopup: (options?: IVideoplayerPopupState) => void;
    onCloseVideoplayerPopup: () => void;
}

export const useVideoplayerPopup = (): IUseVideoplayerPopup => {
  const isVideoPlayerPopupOpen = useState<boolean>('isVideoPlayerModalOpen', () => false);
  const videoplayerState = useState<IVideoplayerPopupState>('videoplayerStatePopup', () => ({}));

  const openVideoplayerPopup = (options?: IVideoplayerPopupState) => {
    isVideoPlayerPopupOpen.value = true;

    videoplayerState.value.title = options?.title;
    videoplayerState.value.subtitle = options?.subtitle;
    videoplayerState.value.type = options?.type;
    videoplayerState.value.ymSendForm = options?.ymSendForm;
    videoplayerState.value.videoLink = options?.videoLink;
  };

  const onCloseVideoplayerPopup = () => {
    isVideoPlayerPopupOpen.value = false;
  };

  return {
    isVideoPlayerPopupOpen,
    videoplayerState,
    openVideoplayerPopup,
    onCloseVideoplayerPopup
  };
};

import {useRuntimeConfig} from "#imports";

interface CityMapListInterface {
  prefix: string,
  list: number[]
}

interface CityMapInterface {
  [key: string]: CityMapListInterface
}

interface ResultObjectInterface {
  [key: number]: string
}

export const redirectToIp = (): ResultObjectInterface => {
  const domain = useRuntimeConfig().public.baseDomain ?? 'astondom.ru'
  const result: ResultObjectInterface = {}
  const cityMap: CityMapInterface = {
    'ekb': {
      prefix: 'ekb',
      list: [
        826294, 472755, 472039, 567293, 555235, 554230, 553972, 556349, 553899, 552927, 552548, 554667,
        548389, 545854, 542415, 543871, 538555, 536199, 524304, 522652, 519324, 559838, 529352, 525369,
        524894, 524925, 504338, 501165, 535120, 498671, 515001, 499068, 536203, 491684, 500059, 487839,
        484638, 1501312, 480041, 484048, 514801, 519969, 1490542, 479613, 1508290, 569665, 567395, 479119,
        694422, 511180, 468898, 511555, 1496152, 480508, 703883, 584222, 581043, 578853, 578071, 571473,
      ]
    },
    'nsk': {
      prefix: 'nsk',
      list: [
        1506272, 1511732, 2027748, 2050915, 2026639, 7779061, 2017623, 1488873, 1489421, 1496745,
        2013162, 2126099, 2022888, 1503834, 2121529, 2023468, 2125072, 1503900, 2123627, 1502020,
      ]
    },
    'tmn': {
      prefix: 'tmn',
      list: [
        1488747, 1503773, 1486462
      ]
    },
  }

  Object.keys(cityMap).map((key: string) => {
    const cityMapObject: CityMapListInterface = cityMap[key] ?? {}
    const cityMapObjectPrefix: string = cityMapObject?.prefix ?? ''

    cityMapObject?.list?.forEach((listItem: number) => {
      result[listItem] = 'https://' + cityMapObjectPrefix + '.' + domain
    })
  })

  return result
};

<template>
  <div class="mobile-menu__list">
    <div
      v-for="(item, idx) in menu"
      :key="`mobile-menu-item-${idx}`"
      class="mobile-menu__item"
    >
      <div class="mobile-menu__item-head">
        <nuxt-link
          class="item"
          :to="`/${item.path}`"
          event=""
          @click.capture="(event) => closeMenu(event, item)"
        >
          {{ item.title }}
        </nuxt-link>
        <button
          v-if="item.hasChildren"
          class="item-button"
          @click="openChildrenMenu(+item.id)"
        >
          <img
            src="~/assets/img/svg/header/arrow-down-black.svg"
            alt="arrow down icon"
            :class="{'active': isOpenedMenu !== `is-opened-${item.id}`}"
          >
        </button>
      </div>

      <template v-if="item.hasChildren">
        <div
          v-if="isOpenedMenu === `is-opened-${item.id}`"
          class="mobile-menu__item-child"
        >
          <div
            v-for="(children, id) in item.children"
            :key="`mobile-menu-children-${id}`"
          >
            <nuxt-link
              class="children"
              :to="`/${children.path}`"
              @click="closeMenu"
            >
              {{ children.title }}
            </nuxt-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useMobileMenu } from '~/composables/useMobileMenu';
import type { IContentMenuItem } from '#sitis/internal/controllers/cms/models/ContentMenu';
import { computed } from '#imports';

const props = defineProps<{ menu: IContentMenuItem[] }>();

const defaultOpenedMenu = computed((): string => {
  const menuHasChildren: IContentMenuItem | undefined = props?.menu?.find((item: IContentMenuItem) => {
    if (item.hasChildren) {
      return item;
    }

    return undefined;
  });

  if (menuHasChildren) {
    return `is-opened-${menuHasChildren.id}`;
  }

  return '';
});

const isOpenedMenu = ref<string>(defaultOpenedMenu.value);

const { onMobileSidebarHandler } = useMobileMenu();
const closeMenu = (event: Event, item: IContentMenuItem) => {
  if (!item?.hasChildren) {
    onMobileSidebarHandler();
    return;
  }
  event.preventDefault();
  openChildrenMenu(+item.id);
};

const openChildrenMenu = (id: number) => {
  const openedValue = `is-opened-${id}`;

  if (isOpenedMenu.value === openedValue) {
    isOpenedMenu.value = '';

    return;
  }

  isOpenedMenu.value = openedValue;
};

</script>

<style lang="scss">
@import "@/assets/scss/media";

.mobile-menu__list {
	display: flex;
	flex-direction: column;
	gap: 22px;
  overflow-y: hidden;
}

.mobile-menu__item {
	display: flex;
	flex-direction: column;
	gap: 22px;
}

.mobile-menu__item-head {
	display: flex;
	align-items: center;
	gap: 6px;

	> .item {
		color: var(--white);
		font-size: 21px;
		font-style: normal;
		font-weight: 600;
		line-height: 110%;
		letter-spacing: -0.63px;
	}

	> .item-button {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 16px;
		height: 16px;
		background-color: var(--secondary);
		border-radius: 50%;

		& > img {
			filter: invert(11%) sepia(72%) saturate(2371%) hue-rotate(251deg) brightness(87%) contrast(105%);
			transform: rotate(180deg);

			&.active {
				transform: rotate(0deg);
			}
		}
	}
}

.mobile-menu__item-child {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.children {
		color: #ffffff80;
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: 180%; /* 30.6px */
		letter-spacing: -0.51px;
	}
}
</style>

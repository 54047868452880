<template>
  <div
    ref="refItem"
    class="desktop-header-nav-item"
    :class="{'hover': hasChildren}"
  >
    <nuxt-link
      :no-prefetch="true"
      :to="`/${item.path}`"
      class="desktop-header-nav-item__link"
      @click.capture="clickItem"
    >
      <span class="desktop-header-nav-item-name">{{ item.title }}</span>

      <span
        v-if="hasChildren"
        class="desktop-header-nav-item__link-down-icon"
      >
        <slot name="chevron-bottom-icon">
          <img
            :src="ArrowDownIcon"
            alt="arrow down"
          >
        </slot>
      </span>
    </nuxt-link>

    <div
      v-if="hasChildren "
      class="desktop-header-nav-item__dropdown"
    >
      <nuxt-link
        v-for="(child) in children"
        :key="`child-${child.path}`"
        class="desktop-header-children-link"
        :to="`/${child.path}`"
        @click="onToggleSubmenu(false)"
      >
        <span class="desktop-header-children-name">{{ child.title }}</span>
      </nuxt-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IContentMenuItem } from '#sitis/internal/controllers/cms/models/ContentMenu';
import { computed, ref, useRouter } from '#imports';
import ArrowDownIcon from '~/assets/img/svg/header/array-full.svg';

interface IHeaderNavItemProps {
	item: IContentMenuItem;
	isClickable?: boolean;
}

const props = withDefaults(defineProps<IHeaderNavItemProps>(), {});
const refItem = ref<HTMLDivElement | null>(null);

const children = computed((): IContentMenuItem[] => (props.item?.children || []).filter((t) => !t.isHidden));

const hasChildren = computed((): boolean => (children.value || []).length > 0);
const isShowSubmenu = ref<boolean>(false);

const onToggleSubmenu = (bool: boolean) => {
  isShowSubmenu.value = bool;
};

const clickItem = (event: Event) => {
  if (!hasChildren.value) {
    return;
  }
  event.preventDefault();
};

</script>

<style lang="scss">
@import "@/assets/scss/media";

.desktop-header-nav-item {
	position: relative;
}

.desktop-header-nav-item__link {
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 9px 0;
	box-sizing: border-box;
	font-weight: 600;
	font-size: 16px;
	line-height: 18px;
	white-space: nowrap;
	transition: all .2s linear;
	color: var(--white);

	&:hover {
		color: var(--secondary) !important;
	}
}

.desktop-header-nav-item__link-down-icon {
	width: 12px;
	height: 6px;
	display: flex;
	align-items: center;
	justify-content: center;

	> img {
		max-width: 10px;
		width: 100%;
		transform: rotate(180deg);
		filter: invert(66%) sepia(24%) saturate(258%) hue-rotate(221deg) brightness(98%) contrast(89%);
		transition: all .2s linear;
	}

	&.active > img {
		transform: rotate(0deg);
		filter: invert(81%) sepia(50%) saturate(1732%) hue-rotate(344deg) brightness(101%) contrast(101%);
	}
}

.desktop-header-nav-item {
	position: relative;

	&.hover {
		&:hover {
			.desktop-header-nav-item__link {
				.desktop-header-nav-item__link-down-icon {
					transform: rotate(180deg);
				}
			}

			.desktop-header-nav-item__dropdown {
				display: flex;
			}
		}
	}
}

.desktop-header-nav-item__dropdown {
	display: none;
	flex-direction: column;
	position: absolute;
	top: calc(100% + 14px) !important;
	left: 0;
	width: max-content;
	max-width: 240px;
	min-width: 240px;
	background: var(--primary-90);
	box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
	border-radius: 0 0 6px 6px;
	padding: 10px 4px;
	box-sizing: border-box;
	z-index: 51;

	& > * {
		padding: 10px 13px;
		box-sizing: border-box;
		font-size: 16px;
		line-height: 18px;
		color: var(--white);
		border-radius: 6px;

		//margin: 0 3px;
		transition: background .2s linear;

		&:hover {
			border-radius: 4px;
			font-weight: 700;
			color: var(--primary);
			background-color: var(--secondary);
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: -20px;
		left: 0;
		right: 0;
		height: 20px;
	}
}

.dropdown-overlay {
	position: fixed;
	inset: 0;
	z-index: 50;
}
</style>

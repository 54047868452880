import { iflatWidget } from 'public/js/iFlatWidget';
export default defineNuxtPlugin({
  name: 'iFlat',
  setup () {
    return {
      provide: {
        iflatWidget
      }
    };
  }
});

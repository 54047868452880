const errorParserServerResponse = (error:any) => {
  if (error?.status === 500) {
    return error._data.message || 'Ошибка сервера';
  }

  const errorMessages:string[] = [];

  if (Array.isArray(error._data)) {
    error._data.forEach((error:any) => {
      errorMessages.push(error?.message || error);
    });
  }

  return errorMessages.join('<br/>') || 'Ошибка сервера';
};

export { errorParserServerResponse };

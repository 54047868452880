import type { Ref } from 'vue';
import type { CurrentPaymentInfoType } from '~/types/MainCalcBlockType';

interface ICountdownPopupState {
}

interface IUseCountdownPopup {
    isCountdownPopupOpen: Ref<boolean>;
    isCountdownWasShown: Ref<boolean>;
    // countdownPopupState: Ref<ICountdownPopupState>;

    openCountdownPopup: (options?: ICountdownPopupState) => void;
    onCloseCountdownPopup: () => void;
}

export const useCountdownPopup = (): IUseCountdownPopup => {
  const isCountdownPopupOpen = useState<boolean>('isCountdownModalOpen', () => false);
  const countdownPopupState = useState<ICountdownPopupState>('countdownPopupStatePopup', () => ({}));
  const isCountdownWasShown = useState<boolean>('countdownWasShown', () => false);

  const openCountdownPopup = (options?: ICountdownPopupState) => {
    isCountdownPopupOpen.value = true;
    isCountdownWasShown.value = true;
  };

  const onCloseCountdownPopup = () => {
    isCountdownPopupOpen.value = false;
  };

  return {
    isCountdownPopupOpen,
    // countdownPopupState,
    isCountdownWasShown,

    openCountdownPopup,
    onCloseCountdownPopup
  };
};

import type { Ref } from 'vue';

interface IUseMobileMenu {
    isMobileMenuOpen: Ref<boolean>;
    isMobileSidebarOpen: Ref<boolean>;
    isMobileFiltersOpen: Ref<boolean>;
    isMobileSortOpen: Ref<boolean>;
    onMenuHandler: () => void;
    onMobileSidebarHandler: () => void;
    onMobileFiltersHandler: () => void;
    onMobileSortHandler: () => void;
}

export const useMobileMenu = (): IUseMobileMenu => {
  const isMobileMenuOpen = useState<boolean>('isMobileMenuOpen', () => false);
  const isMobileSidebarOpen = useState<boolean>('isMobileSidebarOpen', () => false);
  const isMobileFiltersOpen = useState<boolean>('isMobileFiltersOpen', () => false);
  const isMobileSortOpen = useState<boolean>('isMobileSortOpen', () => false);
  const onMenuHandler = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };
  const onMobileSidebarHandler = () => {
    isMobileSidebarOpen.value = !isMobileSidebarOpen.value;
  };

  const onMobileFiltersHandler = () => {
    isMobileFiltersOpen.value = !isMobileFiltersOpen.value;
  };

  const onMobileSortHandler = () => {
    isMobileSortOpen.value = !isMobileSortOpen.value;
  };

  return {
    isMobileMenuOpen,
    isMobileSidebarOpen,
    isMobileFiltersOpen,
    isMobileSortOpen,

    onMenuHandler,
    onMobileSidebarHandler,
    onMobileFiltersHandler,
    onMobileSortHandler
  };
};

<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <TheHeader />
    </div>

    <main class="main">
      <NuxtLoadingIndicator color="#FFC115" />
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </main>
    <div class="footer-wrapper">
      <TheFooter />
    </div>

    <CallbackPopupPopup v-if="isCallBackPopupOpen" />
    <CountdownPopup v-if="isCountdownPopupOpen" />
    <VideoplayerPopup v-if="isVideoPlayerPopupOpen" />
    <ButtonToTop />
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import TheHeader from '~/components/common/TheHeader.vue';
import TheFooter from '~/components/common/TheFooter.vue';

import ButtonToTop from '~/components/wigets/ButtonToTop.vue';
import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { catalogStore } from '#sitis/stores/modules/catalog-store';

import { useCallBackPopup, useCountdownPopup, useVideoplayerPopup } from '#imports';
import {setScripts} from "~/utils/scripts/setScripts";
import setUtmToSessionStorage from "~/utils/forms/setUtmToSessionStorage";

const CallbackPopupPopup = defineAsyncComponent(() => import('~/components/popups/CallbackPopup.vue'));
const CountdownPopup = defineAsyncComponent(() => import('~/components/popups/CountdownPopup.vue'));
const VideoplayerPopup = defineAsyncComponent(() => import('./components/popups/VideoplayerPopup.vue'));

const appConfigStore = useAppConfigStore();
const { isDev } = useRuntimeConfig().public
const { vars, config } = storeToRefs(appConfigStore);

const storeCatalog = catalogStore();
const cmsStore = cmsContentStore();
const { getWishList } = useSitisWish();

const { isCallBackPopupOpen } = useCallBackPopup();
const { isCountdownPopupOpen, isCountdownWasShown, openCountdownPopup } = useCountdownPopup();
const { isVideoPlayerPopupOpen } = useVideoplayerPopup();

const headerCounter = computed(() => {
  const counter = config?.value?.counters?.header || ''

  if (counter !== '') {
    return counter
      .replace(/<!--(.+)-->/gm, '')
      .split('<\/script>')
      .filter((scriptElement: string[]) => scriptElement.length > 0)
      .map((scriptElement: string) => {
        const openScriptTag = scriptElement.match(/<script?(.+)>/gm)?.[0] ?? ''
        const src = scriptElement.match(/(?<=(src="))(.*?)(?=")/g)?.[0] ?? null
        const type = scriptElement.match(/(?<=(type="))(.*?)(?=")/g)?.[0] ?? null
        const children = scriptElement.replace(openScriptTag, '') ?? null
        const async = scriptElement.includes('async') ?? null

        return {
          src,
          type,
          async,
          children
        }
      })
  } else {
    return []
  }
});
const footerCounter = computed(
  () => config?.value?.counters?.footer || ''
);

watchEffect(() => {
  setScripts(footerCounter.value, 'body', isDev)
})

const fetchData = async () => {
  await cmsStore.getMenu();
  await appConfigStore.getConfig();
  await storeCatalog.getCatalog();
};

onServerPrefetch(async () => {
  await fetchData()
});

onMounted(async () => {
  if (Object.keys(cmsStore.menu).length === 0) {
    await fetchData()
  }

  setUtmToSessionStorage()
});

useHead({
  title: 'Жилая и коммерческая недвижимость от застройщика - АСТОН',
  htmlAttrs: {
    lang: 'ru'
  },
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width' },
    { name: 'description', content: 'description' }
  ],
  link: [
    { rel: 'icon', type: 'image/svg+xml', href: () => config?.value?.favicon || '' }
  ],
  script: headerCounter,
});

const startVisibleCountdownTimerPopupBlock = () => {
  const seconds = vars?.value?.['Таймер появления обратного отсчета (в секундах)'];

  if (!seconds) {
    return;
  }

  setTimeout(() => {
    if (!isCountdownWasShown.value) {
      openCountdownPopup();
    }
  }, (Number.parseFloat(seconds) * 1000));
};

onMounted(async () => {
  await getWishList({ extensions: ['characteristics'] });
  console.debug('!!!Build succeeded!!!')
  startVisibleCountdownTimerPopupBlock();
});
</script>

<style lang="scss">
@import "@/assets/scss/media";

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.main,
.loader-main {
	min-height: calc(100vh - 60px);
	flex: 1 0 auto;
	background-position: center;
	background-repeat: no-repeat;

	& > * {
		background-color: white;
	}
}

.loader-main {
	background-image: url("~/assets/img/svg/loading/body-loading.svg");
}

.footer-wrapper {
	width: 100%;
	flex: 0 0 auto;
}

.header-wrapper {
	position: relative;
	z-index: 21;
}

.oldSite-redirect {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px 0;
	background-color: var(--primary);

	& > a {
		color: var(--white);
		font-size: 20px;
		font-weight: 700;
		text-transform: uppercase;
		transition: opacity .2s linear;

		&:hover {
			opacity: .8;
		}
	}
}

@include media('sm') {
	.oldSite-redirect > a {
		font-size: 14px;
	}
}
</style>

export const setScripts = (inlineScript: string, destination: 'head' | 'body' = 'body', isDev = false) => {
  if (typeof document !== 'undefined' && !isDev) {
    let scriptNode = document
      .createRange()
      .createContextualFragment(inlineScript);

    if (destination === 'body') {
      document.head.appendChild(scriptNode)
    } else {
      document.body.appendChild(scriptNode)
    }
  }
}

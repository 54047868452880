import type { Ref } from 'vue';
import type { CurrentPaymentInfoType } from '~/types/MainCalcBlockType';

interface ICallBackPopupState {
    title?: string;
    subtitle?: string;
    type?: string;
    calcParams?: CurrentPaymentInfoType;
    message?: string;
    btnLabel?: string;
    ymSendForm?:string;
}

interface IUseCallBackPopup {
    isCallBackPopupOpen: Ref<boolean>;
    callBackState: Ref<ICallBackPopupState>;

    openCallBackPopup: (options?: ICallBackPopupState) => void;
    onCloseCallBackPopup: () => void;
}

export const useCallBackPopup = (): IUseCallBackPopup => {
  const isCallBackPopupOpen = useState<boolean>('isFastCallBackModalOpen', () => false);
  const callBackState = useState<ICallBackPopupState>('callBackStatePopup', () => ({}));

  const openCallBackPopup = (options?: ICallBackPopupState) => {
    isCallBackPopupOpen.value = true;

    callBackState.value.title = options?.title;
    callBackState.value.subtitle = options?.subtitle;
    callBackState.value.type = options?.type;
    callBackState.value.btnLabel = options?.btnLabel;
    callBackState.value.ymSendForm = options?.ymSendForm;

    if (options && options.calcParams) {
      callBackState.value.calcParams = options?.calcParams;
    }

    if (options && options.message) {
      callBackState.value.message = options.message;
    }
  };

  const onCloseCallBackPopup = () => {
    isCallBackPopupOpen.value = false;
  };

  return {
    isCallBackPopupOpen,
    callBackState,

    openCallBackPopup,
    onCloseCallBackPopup
  };
};

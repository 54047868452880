const hashWidget = "#/iflat", packageVersion = "2.9.0", timestamp = "1707222826641";
export const iflatWidget = {
  path: "",
  styles: ["/static/css/widget_loader.css"],
  stylesReady: !1,
  containerId: "iflatWidget",
  options: {},
  widgetLoaded: !1,
  _setPath: function (t) {
    if (t.devContour) return "https://widget-dev.iflat.io";
    if (t.devMode) return "http://localhost:8080";
    let e = document.getElementsByTagName("script");
    e = Array.prototype.slice.call(e, 0).find((function (t) {
      return t.src && 0 <= t.src.indexOf("widget_loader")
    }));
    return e && e.src.replace("/static/js/widget_loader.js", ""), t.widgetServer || "https://widget.iflat.io"
  },
  _loadStyles: function () {
    const t = this;
    0 < this.styles.length && this.styles.forEach((function (e) {
      const i = document.createElement("link");
      i.href = t.path + e, i.type = "text/css", i.charset = "UTF-8", i.rel = "stylesheet", i.onload = function () {
        t.stylesReady = !0
      }, i.onerror = function () {
        console.error("ERROR:" + i.href + " load styles fail")
      }, document.head.appendChild(i)
    }))
  },

  init: function (t) {
    if (
      this._handlerPostMessage(),
        this.path = this._setPath(t),
        t.domain = window.location.host, (this.options = t).customStyle || this._loadStyles(),
        this.options.isEmbed) return this._createIframe(t),

      this._openWidgetWithUrl(), void this.show();
    this._getConfigWidget(t, function (e) {
      this.config = e, this._isPublish() &&
      (this._createIframe(t), this._openWidgetWithUrl())
    }.bind(this))
  },

  _isPublish: function () {
    return !(!this.config && !this.options) && (this.config.publish || this.options.forcePublish)
  },

  show: function (t) {
    this.options.isEmbed || this._isPublish() ? (t = Object.assign({}, this.options, {
      cookiesEnabled: this._checkCookie(),
      forceRoute: t && t.forceRoute || !1
    }), this.widgetIframe.src = this.path + "/index.html?options_json=" + encodeURI(JSON.stringify(t)) + "&version=" + packageVersion + "&timestamp=" + timestamp, document.body.style.overflow = "hidden", this.widgetContainer && this.widgetContainer.classList.add("open"), this._createEvent({
      event: "open",
      data: {}
    })) : console.error("Р’РёРґР¶РµС‚ РЅРµ РѕРїСѓР±Р»РёРєРѕРІР°РЅ!")
  },

  hide: function () {
    this.widgetLoaded = !1, this.widgetContainer && this.widgetContainer.classList.remove("open"), document.body.style.overflow = "", window.location.hash = "", this.widgetIframe.src = ""
  },

  remove: function () {
    this.widgetOverlay && (this.widgetContainer.removeChild(this.widgetOverlay), this.widgetContainer.classList.remove("iflat-iframe"), delete this.widgetOverlay)
  },

  getDataFromWidget(t) {
    this._sendPostMessage("custom-event", {event: t})
  },

  callAction(t, e) {
    this._sendPostMessage(t, e)
  },

  _getConfigWidget: function (t, e) {
    let i = "https://iflat.io";
    return t.devContour && (i = "https://dev.iflat.io"), t.apiServer && (i = t.apiServer), this._sendRequest("GET", i + "/api/v1/widgets/" + t.id + "?only=button_design,publish", null, e)
  },

  _createButton: function () {
    let t, e;
    this.options.isEmbed || (this.button = document.createElement("button"), t = this.config.button_design, document.documentElement.style.setProperty("--iflatWidget-shadow", t.shadow_color), e = {
      position: "fixed", ...{
        rb: {
          right: 0,
          bottom: 0
        }, rt: {top: 0, right: 0}, lt: {top: 0, left: 0}, lb: {left: 0, bottom: 0}
      }[t.position],
      margin: `${t.margin.top}px ${t.margin.right}px\n       ${t.margin.bottom}px ${t.margin.left}px`,
      "font-size": t.font_size + "px",
      color: t.text_color,
      background: t.background_color,
      width: t.size + "px",
      height: t.size + "px",
      "z-index": t.z_index
    }, this.button.style = Object.entries(e).map((([t, e]) => t + ":" + e)).join(";"), this.button.innerText = t.label, this.button.addEventListener("click", (() => {
      this.show({forceRoute: !0})
    })), this.button.id = "iflat-button", document.body.appendChild(this.button))
  },

  _createIframe: function (t) {
    this.widgetIframe = document.createElement("iframe"), this.widgetIframe.style.position = "relative", this.widgetIframe.id = "iflat-widget-iframe", this.widgetOverlay = document.createElement("div"), this.widgetOverlay.id = "iflat-widget-iframe__overlay", this.widgetOverlay.appendChild(this.widgetIframe), this._createWidgetContainer()
  },

  _createWidgetContainer: function () {
    const t = document.getElementById(this.containerId);
    t ? this.widgetContainer = t : (this.widgetContainer = document.createElement("div"), this.widgetContainer.id = this.containerId, document.body.appendChild(this.widgetContainer)), this.widgetContainer.classList.add("iflat-iframe"), this.options.isEmbed && t.classList.add("_embed"), this.widgetContainer.appendChild(this.widgetOverlay)
  },

  _sendPostMessage: function (t, e) {
    this.widgetIframe && this.widgetLoaded ? this.widgetIframe.contentWindow.postMessage({
      action: t,
      data: e
    }, "*") : this._errorOpenWidget()
  },

  _errorOpenWidget(t) {
    console.error(t || "Р’РёРґР¶РµС‚ РЅРµ Р·Р°РіСЂСѓР¶РµРЅ!")
  },

  _handlerPostMessage: function () {
    window.addEventListener("message", (t => {
      const e = t.data;
      switch (e.action) {
        case"close":
          this._createEvent({event: "close", data: {}}), this.hide();
          break;
        case"setUrl":
          const i = e.data;
          this._createEvent({event: "pageOpen", data: {url: i}}), this._setUrlWidgetOnSite(i);
          break;
        case"custom-event":
          this._createEvent({event: e.event, data: e.data});
          break;
        case"widgetLoaded":
          this.widgetLoaded = !0, this._createEvent({event: "widgetLoaded", data: e.data})
      }
    }))
  },

  _openWidgetWithUrl: function () {
    const t = this._getHashUrl();
    t && (this.show(), this._waitPostMessage("widgetLoaded", function () {
      this.widgetLoaded && this._sendPostMessage("open-url", {url: t})
    }.bind(this)))
  },

  _createEvent: function (t) {
    t = new CustomEvent("iflatWidgetEvent", {detail: t}), window.dispatchEvent(t)
  },

  _waitPostMessage: function (t, e) {
    window.addEventListener("message", function (i) {
      (i && i.data && i.data.action) === t && this._isFunction(e) && e()
    }.bind(this))
  },

  _setUrlWidgetOnSite: function (t) {
    hashWidget + t.url !== window.location.hash && window.history.replaceState(void 0, void 0, hashWidget + t.url)
  },

  _getHashUrl: function () {
    const t = window.location.hash.match(hashWidget);
    return t && t[0] ? window.location.hash.split(hashWidget)[1] : null
  },

  _sendRequest: function (t, e, i, s) {
    let n;
    const o = new XMLHttpRequest;
    for (n in o.open(t, e), i) i.hasOwnProperty(n) && o.setRequestHeader(n, i[n]);
    o.onload = function () {
      let t;
      return 200 <= this.status && this.status < 300 ? (t = JSON.parse(o.response), s && s(t), t) : {
        status: o.status,
        statusText: o.statusText
      }
    }, o.onerror = function () {
      return {status: o.status, statusText: o.statusText}
    }, o.send()
  },

  _checkCookie: function () {
    return "cookie" in document && (0 < document.cookie.length || -1 < (document.cookie = "test").indexOf.call(document.cookie, "test"))
  },

  _isFunction: function (t) {
    return t && "[object Function]" === {}.toString.call(t)
  }
};

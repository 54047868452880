<template>
  <div class="error-block">
    <h1>{{ error?.statusCode }}</h1>
    <div v-if="error?.statusCode === 404">страница не найдена</div>
    <div v-if="error?.statusCode === 500">что-то пошло не так</div>

    <div>
      <button
        class="btn primary --shadow"
        @click="handleError"
      >
        На главную
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
const { error } = defineProps({
  error: Object
});
const handleError = () => {
  clearError({
    redirect: '/'
  });
};
</script>

<style scoped lang="scss">
  @import "@/assets/scss/media";

  .error-block {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    & > h1 {
      font-weight: 700;
      font-size: 76px;
      color: var(--primary);
      text-align: center;
    }

    & > div {
      font-weight: 700;
      font-size: 50px;
      color: var(--primary);
      text-align: center;
      margin-bottom: 20px;
    }
  }

  @include media('lg') {
    .error-block {
      & > h1 {
        font-size: 50px;
      }

      & > div {
        font-size: 40px;
      }
    }

  }

  @include media('sm') {
    .error-block {
      & > h1 {
        font-size: 48px;
      }

      & > div {
        font-size: 30px;
      }
    }

  }
</style>

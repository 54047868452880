import { default as _91_46_46_46cms_93Y7nvq1aZE5Meta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/[...cms].vue?macro=true";
import { default as _91_46_46_46slug_93u8G0vnRbd1Meta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/blog/[...slug].vue?macro=true";
import { default as indexiNHsux2BgFMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/blog/index.vue?macro=true";
import { default as _91_46_46_46slug_93dLEnHKdj9lMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/catalog/[...slug].vue?macro=true";
import { default as temp_catalog_need_32_removeHB9vBWzCwTMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/catalog/temp_catalog_need _remove.vue?macro=true";
import { default as _91_46_46_46slug_93TltQHLjo3BMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/product/[...slug].vue?macro=true";
import { default as indextS2oYpdzjhMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/product/index.vue?macro=true";
import { default as _91_46_46_46slug_93ntgpPFmcuBMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/realty/[...slug].vue?macro=true";
import { default as indexONWUBvXsfwMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/sitemap/index.vue?macro=true";
import { default as indexJHDYu9GSFiMeta } from "/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/wish/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46cms_93Y7nvq1aZE5Meta?.name ?? "cms",
    path: _91_46_46_46cms_93Y7nvq1aZE5Meta?.path ?? "/:cms(.*)*",
    meta: _91_46_46_46cms_93Y7nvq1aZE5Meta || {},
    alias: _91_46_46_46cms_93Y7nvq1aZE5Meta?.alias || [],
    redirect: _91_46_46_46cms_93Y7nvq1aZE5Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/[...cms].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93u8G0vnRbd1Meta?.name ?? "blog-slug",
    path: _91_46_46_46slug_93u8G0vnRbd1Meta?.path ?? "/blog/:slug(.*)*",
    meta: _91_46_46_46slug_93u8G0vnRbd1Meta || {},
    alias: _91_46_46_46slug_93u8G0vnRbd1Meta?.alias || [],
    redirect: _91_46_46_46slug_93u8G0vnRbd1Meta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/blog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexiNHsux2BgFMeta?.name ?? "blog",
    path: indexiNHsux2BgFMeta?.path ?? "/blog",
    meta: indexiNHsux2BgFMeta || {},
    alias: indexiNHsux2BgFMeta?.alias || [],
    redirect: indexiNHsux2BgFMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93dLEnHKdj9lMeta?.name ?? "catalog-slug",
    path: _91_46_46_46slug_93dLEnHKdj9lMeta?.path ?? "/catalog/:slug(.*)*",
    meta: _91_46_46_46slug_93dLEnHKdj9lMeta || {},
    alias: _91_46_46_46slug_93dLEnHKdj9lMeta?.alias || [],
    redirect: _91_46_46_46slug_93dLEnHKdj9lMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/catalog/[...slug].vue").then(m => m.default || m)
  },
  {
    name: temp_catalog_need_32_removeHB9vBWzCwTMeta?.name ?? "catalog-temp_catalog_need _remove",
    path: temp_catalog_need_32_removeHB9vBWzCwTMeta?.path ?? "/catalog/temp_catalog_need%20_remove",
    meta: temp_catalog_need_32_removeHB9vBWzCwTMeta || {},
    alias: temp_catalog_need_32_removeHB9vBWzCwTMeta?.alias || [],
    redirect: temp_catalog_need_32_removeHB9vBWzCwTMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/catalog/temp_catalog_need _remove.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93TltQHLjo3BMeta?.name ?? "product-slug",
    path: _91_46_46_46slug_93TltQHLjo3BMeta?.path ?? "/product/:slug(.*)*",
    meta: _91_46_46_46slug_93TltQHLjo3BMeta || {},
    alias: _91_46_46_46slug_93TltQHLjo3BMeta?.alias || [],
    redirect: _91_46_46_46slug_93TltQHLjo3BMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indextS2oYpdzjhMeta?.name ?? "product",
    path: indextS2oYpdzjhMeta?.path ?? "/product",
    meta: indextS2oYpdzjhMeta || {},
    alias: indextS2oYpdzjhMeta?.alias || [],
    redirect: indextS2oYpdzjhMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93ntgpPFmcuBMeta?.name ?? "realty-slug",
    path: _91_46_46_46slug_93ntgpPFmcuBMeta?.path ?? "/realty/:slug(.*)*",
    meta: _91_46_46_46slug_93ntgpPFmcuBMeta || {},
    alias: _91_46_46_46slug_93ntgpPFmcuBMeta?.alias || [],
    redirect: _91_46_46_46slug_93ntgpPFmcuBMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/realty/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexONWUBvXsfwMeta?.name ?? "sitemap",
    path: indexONWUBvXsfwMeta?.path ?? "/sitemap",
    meta: indexONWUBvXsfwMeta || {},
    alias: indexONWUBvXsfwMeta?.alias || [],
    redirect: indexONWUBvXsfwMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: indexJHDYu9GSFiMeta?.name ?? "wish",
    path: indexJHDYu9GSFiMeta?.path ?? "/wish",
    meta: indexJHDYu9GSFiMeta || {},
    alias: indexJHDYu9GSFiMeta?.alias || [],
    redirect: indexJHDYu9GSFiMeta?.redirect,
    component: () => import("/var/www/astondom-frontend/all/astondom-front-20241108121758/pages/wish/index.vue").then(m => m.default || m)
  }
]
<template>
  <div
    class="city-select"
    :class="{
      '--open': isOpen
    }"
  >
    <div
      class="city-select__label"
      @click="openDropDown"
    >
      <span>{{ currentCity }}</span>
      <img
        alt="city"
        src="~/assets/img/svg/header/array-full.svg"
      />
    </div>
    <div
      v-if="isOpen"
      class="city-select__dropdown"
    >
      <ul>
        <li
          v-for="(city) in citiesList?.favorites"
          :key="`city-select-${ city.id }`"
          @click="() => onSetCityUser(city)"
        >
          {{ city.name }}
        </li>
      </ul>
    </div>
    <div
      v-if="isOpen"
      class="city-select__close"
      @click="closeDropDown"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import { useUserCity } from '#imports';
import type { CityListCityType } from '~/composables/useUserCity';

const appConfigStore = useAppConfigStore();
const { config } = storeToRefs(appConfigStore);

const { getAllCitiesList, setCityUser, citiesList } = useUserCity();

const isOpen = ref<boolean>(false);

const openDropDown = async () => {
  isOpen.value = true;
  await loadCitiesList();
};

const closeDropDown = () => {
  isOpen.value = false;
};

const loadCitiesList = async () => {
  if (citiesList.value && Object.keys(citiesList.value).length > 0) {
    return;
  }
  await getAllCitiesList();
};

const onSetCityUser = async (city: CityListCityType) => {
  await setCityUser(city.id);
};

const currentCity = computed(() => config.value?.city?.model?.name || config?.value?.city?.data?.city_name);
</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.city-select {
	position: relative;

	&.--open {
		.city-select__label {
			color: var(--secondary);

			span::after {
				border-color: var(--secondary);
			}
		}

		.city-select__label img {
			transform: rotate(360deg);
			filter: invert(79%) sepia(43%) saturate(1129%) hue-rotate(343deg) brightness(101%) contrast(103%);
		}
	}
}

.city-select__label {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 100%; /* 16px */
	letter-spacing: -0.16px;
	color: var(--white);
	gap: 12px;

	img {
		width: 10px;
		height: 6px;
		transition: transform 0.2s;
		transform: rotate(180deg);
		filter: invert(66%) sepia(24%) saturate(258%) hue-rotate(221deg) brightness(98%) contrast(89%);
	}

	span {
		display: flex;
		position: relative;
		padding-bottom: 4px;
		top: 1px;

		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			height: 1px;
			border-bottom: 1px dotted var(--primary-60);
		}
	}

	&:hover {
		color: var(--secondary);

		& > img {
			filter: invert(79%) sepia(43%) saturate(1129%) hue-rotate(343deg) brightness(101%) contrast(103%);
		}

		span::after {
			border-color: var(--secondary);
		}
	}
}

.city-select__dropdown {
	position: absolute;
	z-index: 100;
	left: -12px;
	top: 197%;
	width: 220px;
	background: var(--primary-90);
	box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
	border-radius: 0 0 6px 6px;

	ul {
		margin: 4px 0;

		& > li {
			cursor: pointer;
			padding: 8px 24px;
			font-size: 16px;
			line-height: 26px;
			color: #FFF;

			&:hover {
				margin: 0 4px;
				border-radius: 4px;
				font-weight: 700;
				color: var(--primary);
				background-color: var(--secondary);
			}
		}
	}
}

.city-select__close {
	position: fixed;
	inset: 0;
	z-index: 99;
}

@include media('xxl') {
	.city-select__label {
		font-size: 16px;
	}
}

@include media('xxl') {
	.city-select__label {
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
	}

	.city-select__dropdown {
		ul li {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
		}
	}
}

@include media('md') {
	.city-select__dropdown {
		top: 196%;
	}
}
</style>

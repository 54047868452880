<template>
  <button
    class="v-hamburger"
    :class="{ __isOpen: isOpen }"
    @click="emits('onChangeOpenMenu')"
  >
    <span class="v-humburger_line-block">
      <span class="v-hamburger_line __first"></span>
      <span class="v-hamburger_line __second"></span>
      <span class="v-hamburger_line __third"></span>
    </span>
  </button>
</template>

<script setup lang="ts">

const props = defineProps<{
	isOpen:boolean;
}>();

const emits = defineEmits<{
	onChangeOpenMenu:[]
}>();
</script>
<style lang="scss" scoped>
@import "@/assets/scss/media";

.v-humburger_line-block {
  position: relative;
  width: 32px;
  height: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.v-hamburger {
  position: relative;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }

  &.__isOpen {
    width: 32px;
    height: 32px;

    & .v-humburger_line-block {
      width: 32px;
      height: 32px;
    }

    & .v-hamburger_line {
      &:first-child {
        transform: translateY(5px) rotate(135deg);
      }

      &:nth-child(2) {
        transform: scale(0);
      }

      &:last-child {
        transform: translateY(-4px) rotate(-135deg);
      }
    }
  }

  &.__isOpen .v-hamburger_line.__third,
  &.__isOpen .v-hamburger_line.__first {
    width: 32px;
  }

  &.__isOpen .v-hamburger_line.__third {
    bottom: 10px;
    left: 0;
  }

  &.__isOpen .v-hamburger_line.__first {
    top: 11px;
    left: -1px;
  }

  @include media('xs') {
    width: 28px;
    height: 28px;

    &.__isOpen .v-hamburger_line.__first {
      top: 7px;
      left: 0;
    }

    &.__isOpen {
      width: 28px;
      height: 28px;

      & .v-humburger_line-block {
        width: 28px;
        height: 28px;
      }
    }
  }
}

.v-hamburger_line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: var(--white);
  transition: transform .3s ease-in-out;

  &:first-child {
    //width: 24px;
    position: absolute;
    top: 0;
  }

  &:last-child {
    //width: 8px;
    position: absolute;
    bottom: 0;
  }
}
</style>

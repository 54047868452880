<template>
  <header>
    <HomeSaleStart
      v-if="isCheckSale"
      :link="vars['Горячее предложение_ссылка']"
      :title="vars['Горячее предложение_текст']"
    />
    <div
      v-if="isFixedHeader"
      :style="{height:headerHeight + 'px'}"
    />
    <div
      id="header"
      :class="{
        'opened-sidebar': isMobileSidebarOpen,
        'fixed':isFixedHeader
      }"
      class="header-root"
    >
      <div class="container header-container">
        <div class="header-container__top">
          <div class="burger-logo">
            <div>
              <NuxtLink
                to="/"
                class="header-logo"
              >
                <img
                  width="85"
                  height="27"
                  alt="logo"
                  src="~/assets/img/svg/header/logo.svg"
                >
              </NuxtLink>
            </div>
            <CitySelect
              class="header-city"
            />
            <CheckAndAgreeUserCityToast
              v-if="isShowUserVisitToast"
              class="user-city__toast"
            />
          </div>

          <div class="header__nav">
            <template
              v-for="(item) in menu"
              :key="`DesktopHeaderNavItem-${item.path}`"
            >
              <HeaderNavItem
                :is-clickable="true"
                :item="item"
              />
            </template>
          </div>

          <div class="header__controls">
            <div
              class="header-callback --desktop"
              @click="openCallbackFormPopup"
            >
              <img
                src="~/assets/img/svg/header/phone.svg"
                alt="wish"
              />
              <span>Связаться</span>
            </div>
            <a
              class="header-callback --mobile"
              :href="getCleanedPhoneForHref(vars['Телефон офиса продаж'] || '')"
            >
              <img
                src="~/assets/img/svg/header/phone.svg"
                alt="wish"
              />
              <span>Связаться</span>
            </a>

            <template v-if="profileInfoLink.link && profileInfoLink.icon">
              <NuxtLink
                :to="profileInfoLink.link"
                class="header__control-link"
                :target="profileInfoLink.link.includes('https://') ? '_blank': '_self'"
              >
                <img
                  :src="profileInfoLink.icon"
                  alt="wish"
                >
              </NuxtLink>
            </template>

            <NuxtLink
              class="header__control-link"
              to="/wish"
            >
              <img
                src="~/assets/img/svg/header/wish.svg"
                alt="wish"
              >
              <span
                v-if="wishlistCount"
                class="header-wish__count"
              >{{ wishlistCount }}</span>
            </NuxtLink>

            <template v-if="searchInfoLink.link && searchInfoLink.icon">
              <NuxtLink
                :to="searchInfoLink.link"
                class="header__control-link"
                :target="searchInfoLink.link.includes('https://') ? '_blank': '_self'"
              >
                <img
                  :src="searchInfoLink.icon"
                  alt="wish"
                >
              </NuxtLink>
            </template>
          </div>
          <div
            class="burger-btn"
          >
            <HeaderHamburger
              :is-open="isMobileSidebarOpen"
              @click="openMobileMenu"
            />
          </div>
        </div>
      </div>
    </div>
    <LazyMobileSidebar
      v-if="$viewport.isLessThan('xl')"
      :is-open="isMobileSidebarOpen"
      z-index="10"
      bg-z-index="5"
      offset-top="56px"
      width="80%"
      bg-color="transparent"
    >
      <MobileMenuContent
        :menu="menu"
      >
        <template #footer>
          <HeaderSocials
            :socials-list="socialsList"
            :phone="vars['Телефон офиса продаж'] || ''"
          />
        </template>
      </MobileMenuContent>
    </LazyMobileSidebar>
  </header>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useMobileMenu } from '~/composables/useMobileMenu';
import MobileMenuContent from '~/components/common/Header/MobileMenuContent.vue';

import { cmsContentStore } from '#sitis/stores/modules/cms-content-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
import CitySelect from '~/components/common/Header/CitySelect.vue';
import HeaderNavItem from '~/components/common/Header/HeaderNavItem.vue';
import type { ComputedRef } from '#imports';
import { useAsset, useUserVisit } from '#imports';
import type { FooterSocialsType } from '~/components/common/TheFooter.vue';
import CheckAndAgreeUserCityToast from '~/components/common/CheckAndAgreeUserCity/CheckAndAgreeUserCityToast.vue';
import HomeSaleStart from '~/components/common/Header/HomeSaleStart.vue';
import HeaderHamburger from '~/components/common/Header/HeaderHamburger.vue';

const $viewport = useViewport();
const HeaderSocials = defineAsyncComponent(() => import('~/components/common/Header/MobileHeaderSocials.vue'));
const LazyMobileSidebar = defineAsyncComponent(() => import('~/components/sidebar/LazyMobileSidebar.vue'));
const { isMobileSidebarOpen, onMobileSidebarHandler } = useMobileMenu();
const { wishlistCount } = useSitisWish();

const cmsStore = cmsContentStore();
const { menu } = storeToRefs(cmsStore);

const $route = useRoute();
const isFixedHeader = ref<boolean>(false);
const headerHeight = ref($viewport.isGreaterThan('lg') ? 60 : 56);

const openMobileMenu = () => {
  onMobileSidebarHandler();
};

const appConfigStore = useAppConfigStore();
const { config, vars } = storeToRefs(appConfigStore);
const { ssrContext } = useNuxtApp();
const { openCallBackPopup } = useCallBackPopup();
const userVisited = useCookie('user-visited');

const { isShowSelectCityToast } = useUserVisit();
const isShowUserVisitToast = computed(() => (!userVisited.value && isShowSelectCityToast.value));

const openCallbackFormPopup = () => {
  openCallBackPopup({
    title: 'Заявка на обратный звонок',
    ymSendForm: 'LEAD_zakaz_zvonok_shapka'
  });
};

const socialsList: ComputedRef<FooterSocialsType[]> = computed(() => {
  const list = [];

  if (vars.value['Соцсети_VK']) {
    list.push({
      link: vars.value['Соцсети_VK'],
      alt: 'VK',
      icon: useAsset('img/svg/footer/vk.svg')
    });
  }

  if (vars.value['Соцсети_Telegram']) {
    list.push({
      link: vars.value['Соцсети_Telegram'],
      alt: 'Telegram',
      icon: useAsset('img/svg/footer/tg.svg')
    });
  }

  if (vars.value['Соцсети_YouTube']) {
    list.push({
      link: vars.value['Соцсети_YouTube'],
      alt: 'YouTube',
      icon: useAsset('img/svg/footer/youtube.svg')
    });
  }

  if (vars.value['Соцсети_Дзен']) {
    list.push({
      link: vars.value['Соцсети_Дзен'],
      alt: 'Дзен',
      icon: useAsset('img/svg/footer/yadzen.svg')
    });
  }

  return list;
});

const handleScroll = (event: Event) => {
  headerHeight.value = document.getElementById('header')?.offsetHeight || 0;
  const fixedHeight = isCheckSale.value ? 60 : 0;

  const isFixedCheck = ref(((event?.target as Document).scrollingElement?.scrollTop || 0) > fixedHeight);

  if (isFixedHeader.value !== isFixedCheck.value) {
    isFixedHeader.value = isFixedCheck.value;
  }
};

const profileInfoLink = computed(() => {
  const icon = vars.value?.['иконка шапка лк'] || '';
  const link = vars.value?.['ссылка шапка лк'] || '';
  return { icon, link };
});

const searchInfoLink = computed(() => {
  const icon = vars.value?.['иконка шапка поиск'] || '';
  const link = vars.value?.['ссылка шапка поиск'] || '';
  return { icon, link };
});

const isCheckSale = computed(() => {
  const isShow = Boolean(vars.value?.['Горячее предложение_ссылка'] && vars?.value?.['Горячее предложение_текст'] && $route.fullPath === '/');
  if (isShow && !isFixedHeader.value) {
    if (process.client) {
      document.body.classList.add('sale');
    }
  } else if (process.client) {
    document.body.classList.remove('sale');
  }
  return isShow;
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

useJsonld(() => ({
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'ООО "ГРУППА АСТОН"',
  url: process.server ? ssrContext!.event.node.req.headers.host : window.location.host,
  address: {
    '@type': 'PostalAddress',
    // postalCode: ''
    addressLocality: config.value?.address_city || 'Екатеринбург',
    streetAddress: vars.value?.['Адрес офиса продаж'] || ''
  },
  telephone: vars.value?.['Телефон офиса продаж'] || '',
  email: vars.value?.['Email офиса продаж'] || '',
  logo: config.value?.logoFront || ''
}));
</script>

<style lang="scss">
@import "@/assets/scss/media";

header {
	position: relative;
	z-index: 12;

	.user-city__toast {
		position: absolute;
		z-index: 10;
		left: 92px;
		top: 172%;
	}
}

.header-root {
	padding: 14px 0;
	background-color: var(--primary);
	z-index: 10;
	box-shadow: 0 5px 10px 0 rgb(46 145 235 / 5%);
	transition: background-color .2s linear;

	&.fixed {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		z-index: 999;
		transition: top .4s;

		//animation: header-opacity 0.8s;
	}

	& .desktop-header-nav-item__dropdown {
		top: calc(100% + 15px);
		left: -25px;
	}
}

.header-container {
	display: flex;
	flex-direction: column;
	gap: 13px;
}

.header-city {
	margin-top: 3px;
}

.header-container__top {
	display: flex;
	align-items: center;
	gap: 30px;
	justify-content: space-between;
}

.header__controls {
	display: flex;
	gap: 15px;
}

.header__control-link {
	position: relative;
	width: 28px;
	min-width: 28px;
	height: 28px;
	border-radius: 50%;
	background-color: var(--primary-80);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all .3s linear;

	&::before {
		content: '';
		border-radius: 50%;
		position: absolute;
		width: 29px;
		height: 29px;
		background: linear-gradient(180deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 0%) 100%);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		display: block;
	}

	&::after {
		content: '';
		border-radius: 50%;
		position: absolute;
		background: #50347C;
		inset: 0;
		z-index: 1;
		display: block;
		transition: background .3s linear;
	}

	& > img {
		filter: invert(86%) sepia(43%) saturate(3358%) hue-rotate(345deg) brightness(105%) contrast(101%);
		z-index: 2;
		position: relative;
	}

	&:hover {
		background: var(--secondary);

		&::before {
			background: linear-gradient(180deg, rgb(255 211 90) 0%, rgb(255 193 21) 100%);
		}

		&::after {
			background: var(--secondary);
		}

		& > img {
			filter: invert(11%) sepia(72%) saturate(2371%) hue-rotate(251deg) brightness(87%) contrast(105%);
		}
	}
}

.header-wish__count {
	position: absolute;
	right: -15px;
	top: -10px;
	background: var(--secondary);
	width: 20px;
	min-width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	font-size: 12px;
	z-index: 2;
}

.header-callback {
	display: flex;
	max-height: 30px;
	align-items: center;
	background: #50347C;
	border-radius: 56px;
	padding: 4px 9px;
	color: #D8D2E2;
	gap: 6px;
	cursor: pointer;
	position: relative;
	z-index: 0;
	transition: all .3s linear;

	& > img {
		filter: invert(86%) sepia(43%) saturate(3358%) hue-rotate(345deg) brightness(105%) contrast(101%);
		z-index: 2;
		position: relative;
	}

	&::before {
		content: '';
		border-radius: 56px;
		position: absolute;
		background: linear-gradient(180deg, rgb(255 255 255 / 20%) 0%, rgb(255 255 255 / 0%) 100%);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		width: calc(100% + 1px);
		height: calc(100% + 1px);
		display: block;
	}

	&::after {
		content: '';
		border-radius: 56px;
		position: absolute;
		background: #50347C;
		inset: 0;
		z-index: 1;
		display: block;
		transition: background .3s linear;
	}

	&:hover {
		color: #3C1D6D;

		&::before {
			background: linear-gradient(180deg, rgb(255 211 90) 0%, rgb(255 193 21) 100%);
		}

		&::after {
			background: var(--secondary);
		}

		& > img {
			filter: invert(11%) sepia(72%) saturate(2371%) hue-rotate(251deg) brightness(87%) contrast(105%);
		}
	}

	& > span {
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px; /* 100% */
		letter-spacing: -0.14px;
		vertical-align: middle;
		display: inline;
		position: relative;
		z-index: 2;
	}

  &.--mobile {
    display: none;
  }
}

.burger-btn {
  cursor: pointer;
	display: none;
	width: 32px;
	min-width: 32px;
	height: 32px;

	& > img {
		width: 100%;
		height: 100%;
	}
}

.header-select__flat {
	border-radius: 15px !important;
}

.burger-logo {
	display: flex;
	align-items: flex-end;
	gap: 10px;
	position: relative;
	top: -3px;
}

.header-logo {
	display: flex;
	width: 95px;
	min-width: 85px;
	height: 27px;
	padding-right: 10px;
	position: relative;
	top: -3px;

	&::before {
		content: '';
		height: 22px;
		width: 1px;
		background: var(--primary-80);
		position: absolute;
		right: 0;
		bottom: -3px;
	}

	& > img {
		width: 100%;
		height: 100%;
	}
}

.header__nav {
	display: flex;
	align-items: center;
	gap: 25px;

	& .desktop-header-nav-item__link {
		color: var(--white);
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px; /* 87.5% */
		letter-spacing: -0.16px;
	}
}

.search-input__header {
	width: 530px;
}

.header-social__desc {
	margin-left: 56px;

}

@keyframes header-opacity {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@include media('xl') {
	//.header-logo {
	//  min-width: 140px;
	//  width: 140px;
	//  margin-right: 20px;
	//  height: 80px;
	//}

	.header-container__top {
		gap: 6px;
	}

	.header-callback {
		border-radius: 50%;
		width: 28px;
		min-width: 28px;
		height: 28px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: none;
		}
	}

	.header__nav {
		gap: 12px;
	}

	.header__controls {
		gap: 5px;
	}

	.header-phone {
		gap: 10px;

		& > span {
			display: none;
		}
	}
}

@include media('lg') {
	header {
		.user-city__toast {
			top: 165%;
		}
	}

	.header-city {
		display: none;
	}

	.header-root {
		padding: 16px 0;
	}

	.header-callback {
		width: fit-content;
		max-height: 28px;
		border-radius: 56px;
		padding: 4px 9px;
		min-width: initial;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;

		& > span {
			display: inline-block;
		}
	}

	.burger-logo {
		align-items: center;
		top: initial;
	}

	.header-container {
		display: flex;
		justify-content: space-between;
	}

	.burger-btn {
		display: flex;
	}

	.header__nav {
		display: none;
	}

	.header-phone {
		& span {
			display: none;
		}
	}

	.header-logo {
		min-width: 91px;
		width: 91px;
		height: 25px;

		&::before {
			content: none;
		}
	}

	.header-social__desc {
		margin-left: 20px;
		margin-right: 20px;

		& > .header-contacts-wrapper {
			display: none;
		}
	}

	.header__controls {
		gap: 12px;
	}
}

@include media('sm') {
	header {
		.user-city__toast {
			left: 24px;
		}
	}

	.header-container__top {
		align-items: center;
	}

	.header__controls {
		gap: 8px;
	}

	.header-wish__count {
		width: 18px;
		min-width: 18px;
		height: 18px;
	}

	.header__control-link {
		width: 22px;
		min-width: 22px;
		height: 22px;

		&::before {
			width: 23px;
			height: 23px;
		}

		img {
			width: 14px;
			height: 13px;
		}
	}

	.header-callback {
		height: 22px;
		font-size: 11px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px; /* 127.273% */
		letter-spacing: -0.11px;

		img {
			width: 12px;
			height: 12px;
		}

    &.--mobile {
      display: flex;
    }

    &.--desktop {
      display: none;
    }
	}
}
</style>

const parseUtmLabels = () => {
  let searchStr = window.location?.search || '';
  const searchObject: Record<string, string> = {};
  const utmLabels: Record<string, string> = {};

  if (!searchStr) {
    return utmLabels;
  }

  searchStr = searchStr.slice(1);
  searchStr.split('&').forEach((str: string) => {
    const [key, value] = str.split('=');
    searchObject[key] = value;

  });

  if (Object.keys(searchObject).length <= 0) {
    return utmLabels;

  }

  Object.keys(searchObject).forEach((key: string) => {
    if (key.includes('utm_')) {
      utmLabels[key] = searchObject[key];
    }
  });

  if (Object.keys(utmLabels).length <= 0) {
    return utmLabels;
  }

  return utmLabels;
};

export {
  parseUtmLabels
};
